<form [formGroup]="requestForm">
  <div class="rt-row">    
    <div class="rt-column desk-12">
      <div class="rt-column-content">      
        <p>
          We'll send you an email with your login link. 
          Once you receive it, click the link to access your account. 
          After logging in, you can update your password in the profile settings.          
        </p>
      </div>
    </div>
    <div class="rt-column desk-12">
      <div class="rt-column-content">            
        <div class="field">
          <label for="email">Email</label>
          <input
            [style]="{ width: '100%' }"
            id="email" 
            formControlName="email"
            type="email"
            pInputText
          />
        </div> 
      </div>
    </div>
  </div>
</form>
<div style="display: flex; justify-content: flex-end; gap: 8px; margin-top: 12px;">
  <p-button
    (click)="close()"
    severity="secondary"
    [text]="true"
    [outlined]="true"
    [rounded]="true"
    label="Close"
  ></p-button>
  <p-button
    (click)="sendLoginCode()"
    severity="primary"
    [rounded]="true"
    label="Send"
    [disabled]="requestForm.invalid"
    [loading]="sending"
  ></p-button>
</div>