export const environment = {
  // Riterz
  riterz_api_url: 'https://dev.api.riterz.io/api/',
  riterz_client_id: '9883e0d1-7184-4fc0-af2f-f6f777af464f',
  riterz_access_token_name: 'riterz_access_token',
  riterz_refresh_token_name: 'riterz_refresh_token',

  riterz_terms_url: 'https://riterz.com/terms-conditions/',

  navus_api_url: 'https://staging.e-materials.com/api/',
  navus_url: 'https://config.navus-hub.com/',
  navus_access_token_name: 'navus_access_token',

  zitcha_iframe_url: 'https://dev.media.riterz.io/',
  zitcha_access_token_name: 'zitcha_access_token',

  recaptcha_site_key: '6LfAW9gkAAAAANtDnfSBu4w-0uZjuFCgVEsRhGte',
  google_analytics_id: 'G-HBBEBD89E6'
};
