import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";
import { AppService } from "../../services/app.service";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { UserService } from "riterz-core";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
  ],
  templateUrl: './login-code-request.component.html'
})

export class LoginCodeRequestComponent {
  requestForm: FormGroup;

  sending: boolean = false;

  constructor(
    private appService: AppService,
    private dialogConfig: DynamicDialogConfig,
    private dialogRef: DynamicDialogRef,
    private userService: UserService,
  ) {
    this.requestForm = new FormGroup({
      email: new FormControl('', [
        Validators.required,
        Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,8}$/)
      ]),
    });

    if (this.dialogConfig.data?.email) {
      this.requestForm.get('email')?.setValue(this.dialogConfig.data.email);
    }
  }

  sendLoginCode() {
    const params = this.requestForm.value;

    this.sending = true;
    this.userService
      .loginWithCodeRequest(params)
      .subscribe({
        next: () => {
          this.dialogRef.close({ hasChanges: true });
          this.sending = false;
        },
        error: (error) => {
          this.appService.showToast({ 
            severity: 'error', 
            summary: `Error`, 
            detail: error.message 
          });
          this.sending = false;
        }
      });
  }

  close() {
    this.dialogRef.close({ hasChanges: false });
  }

}